import { Range, getTrackBackground } from 'react-range';
import cx from 'classnames';
import { Dispatch, SetStateAction } from 'react';

const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

const MAX_SAVINGS_LIMIT = 100000;
const MAX_CONTRIBUTIONS_LIMIT = 10000;

interface StateHandlersType {
  setCurrentSavings: Dispatch<SetStateAction<string>>;
  setRateOfReturn: Dispatch<SetStateAction<number | 'custom'>>;
  setCustomRate: Dispatch<SetStateAction<string>>;
  setExtraContribution: Dispatch<SetStateAction<string>>;
  setContributionFrequency: Dispatch<SetStateAction<number>>;
  setSavingsAgeRange: Dispatch<SetStateAction<[number, number]>>;
}

interface SavingsCalculatorFormData {
  currentSavings: string;
  rateOfReturn: number | 'custom';
  customRate: string;
  extraContribution: string;
  contributionFrequency: number;
  savingsAgeRange: [number, number];
  estimatedSavings: number;
}

export interface SavingsCalculatorFormProps {
  stateHandlers: StateHandlersType;
  formData: SavingsCalculatorFormData;
  currentSavingsHeader?: string;
  contributionsHeader?: string;
  frequencyHeader?: string;
  frequencyOptions?: (
    | 'Bi-weekly'
    | 'Daily'
    | 'Monthly'
    | 'Quarterly'
    | 'Weekly'
    | 'Yearly'
  )[];
  savingsRangeHeader?: string;
  savingsRateHeader?: string;
  savingsRateBody?: string;
  totalSavingsHeader?: string;
}

export const SavingsForm = ({
  stateHandlers,
  formData,
  currentSavingsHeader,
  contributionsHeader,
  frequencyHeader,
  frequencyOptions,
  savingsRangeHeader,
  savingsRateHeader,
  savingsRateBody,
  totalSavingsHeader
}: SavingsCalculatorFormProps) => {
  const {
    currentSavings,
    rateOfReturn,
    customRate,
    extraContribution,
    contributionFrequency,
    savingsAgeRange
  } = formData;

  const {
    setCurrentSavings,
    setRateOfReturn,
    setCustomRate,
    setExtraContribution,
    setContributionFrequency,
    setSavingsAgeRange
  } = stateHandlers;

  const handleMonetaryValueChange = (
    value: string,
    func: Dispatch<SetStateAction<string>>
  ) => {
    if (isNumeric(value) || value === '') {
      func(value === '' ? '0' : value);
    }
  };

  const handleCustomRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumeric(e.target.value.substring(0, e.target.value.length - 1))) {
      setCustomRate(e.target.value.substring(0, e.target.value.length - 1));
      setRateOfReturn('custom');
    } else {
      setCustomRate('0');
      setRateOfReturn('custom');
    }
  };

  return (
    <div className="w-full rounded-2xl bg-[#F3F6F5] p-6 desktop:w-1/3">
      {/* Form Fields */}
      {/* Initial Deposit */}
      <div className="mb-4">
        {currentSavingsHeader && (
          <label htmlFor="currentSavings" className="mb-1 block leading-6">
            {currentSavingsHeader}
          </label>
        )}
        <div className="relative">
          <input
            id={'currentSavings'}
            data-testid="current-savings"
            type="text"
            inputMode="numeric"
            value={
              currentSavings
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0
                  }).format(parseInt(currentSavings))
                : ''
            }
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d]/g, '');
              const numericValue = parseFloat(value.replace(/,/g, ''));

              if (numericValue <= MAX_SAVINGS_LIMIT || isNaN(numericValue)) {
                handleMonetaryValueChange(value, setCurrentSavings);
              }
            }}
            className="flex h-12 w-full items-center justify-center rounded-pill border border-light-grey bg-transparent px-1 pt-1 text-center font-medium"
          />
        </div>
      </div>
      {/* Regular Contributions */}
      <div className="mb-6">
        {contributionsHeader && (
          <label htmlFor="extraContribution" className="mb-1 block leading-6">
            {contributionsHeader}
          </label>
        )}
        <div className="relative">
          <input
            id="extraContribution"
            type="text"
            inputMode="numeric"
            max={10000}
            value={
              extraContribution
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0
                  }).format(parseInt(extraContribution))
                : ''
            }
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d]/g, '');
              const numericValue = parseFloat(value.replace(/,/g, ''));

              if (
                numericValue <= MAX_CONTRIBUTIONS_LIMIT ||
                isNaN(numericValue)
              ) {
                handleMonetaryValueChange(value, setExtraContribution);
              }
            }}
            className="flex h-12 w-full items-center justify-center rounded-pill border border-light-grey bg-transparent px-1 pt-1 text-center font-medium"
          />
        </div>
      </div>
      {/* Contribution Frequency */}
      <div className="mb-8">
        {frequencyHeader && (
          <label className="mb-1 block  leading-6">{frequencyHeader}</label>
        )}
        {frequencyOptions && (
          <div className="flex flex-wrap justify-center gap-2">
            {frequencyOptions.map((label) => {
              let freq = 12;
              switch (label) {
                case 'Bi-weekly':
                  freq = 26;
                  break;
                case 'Daily':
                  freq = 365;
                  break;
                case 'Monthly':
                  break;
                case 'Quarterly':
                  freq = 4;
                  break;
                case 'Weekly':
                  freq = 52;
                  break;
                case 'Yearly':
                  freq = 1;
                  break;
              }
              return (
                <button
                  key={label}
                  className={cx(
                    'flex h-12 w-full flex-grow basis-1/4 items-center justify-center rounded-pill bg-white p-1 font-medium',
                    contributionFrequency === freq
                      ? 'border-[1.5px] border-navy'
                      : 'border-none'
                  )}
                  onClick={() => setContributionFrequency(freq)}
                >
                  {label}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {/* Savings Age Range */}
      <div className="mb-[42px]">
        {savingsRangeHeader && (
          <label className="mb-2 block  leading-6">
            {savingsRangeHeader}:{' '}
            {Math.max(0, savingsAgeRange[1] - savingsAgeRange[0])} years
          </label>
        )}
        <Range
          values={savingsAgeRange}
          step={1}
          min={1}
          max={18}
          onChange={(values) => setSavingsAgeRange(values as [number, number])}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="mb-8 mt-14 h-2 rounded-lg bg-blue pr-2"
              style={{
                background: getTrackBackground({
                  values: savingsAgeRange,
                  colors: ['#fff', '#00D9B4', '#fff'],
                  min: 1,
                  max: 18
                }),
                position: 'relative',
                marginInline: 'auto',
                width: 'calc(100% - 16px)'
              }}
            >
              <div
                style={{
                  background:
                    'linear-gradient(81deg, #035C67 9.49%, #00D9B4 109.41%)',
                  position: 'absolute',
                  height: '100%',
                  left: `${((savingsAgeRange[0] - 1) / 17) * 100}%`,
                  width: `${
                    ((savingsAgeRange[1] - savingsAgeRange[0]) / 17) * 100
                  }%`
                }}
              />
              {children}
            </div>
          )}
          renderThumb={({ index, props }) => (
            <div {...props} className="relative -mt-[1.15rem] !h-15 !w-16">
              <div className="absolute bottom-0 left-5 flex h-6 w-6 items-center justify-center rounded-full border-2 border-navy bg-white shadow-lg" />
              <div className="absolute bottom-8 left-1/2 flex -translate-x-1/2 transform text-nowrap rounded bg-navy px-2 py-1 text-center text-xs font-medium leading-[18px] text-white">
                {savingsAgeRange[index]} y/o
                <div className="absolute -bottom-1 left-1/2 h-2 w-2 -translate-x-1/2 rotate-45 transform bg-navy" />
              </div>
            </div>
          )}
        />
      </div>
      {/* Savings Rate */}
      <div>
        {savingsRateHeader && (
          <label className="mb-2 block  leading-6">{savingsRateHeader}</label>
        )}
        {savingsRateBody && <p className="mb-4 text-sm">{savingsRateBody}</p>}
        <div className="flex gap-2">
          {['Infinity 5%', 'Max 3%', 'Core 2%', 'Custom'].map(
            (label, index) => {
              const rate = [0.05, 0.03, 0.02, 'custom'][index];
              return (
                <button
                  key={label}
                  data-testid={rate === 'custom' ? 'custom-rate-button' : ''}
                  className={cx(
                    'flex h-12 flex-grow items-center justify-center rounded-lg bg-white px-1 py-1 font-medium',
                    rateOfReturn === rate
                      ? 'border-[1.5px] border-navy'
                      : 'border-none'
                  )}
                  onClick={() => setRateOfReturn(rate as any)}
                >
                  {label}
                </button>
              );
            }
          )}
        </div>
        {rateOfReturn === 'custom' && (
          <div className="relative mt-8">
            <label
              htmlFor="customRate"
              className="absolute -top-2 left-7 bg-[#F3F6F5] px-[2px] text-xs  leading-4"
            >
              Custom annual return
            </label>
            <input
              id="customRate"
              data-testid="custom-rate"
              type="text"
              inputMode="numeric"
              value={customRate + '%'}
              onChange={handleCustomRateChange}
              className="flex h-12 w-full items-center justify-center rounded-pill border border-light-grey bg-transparent pl-8 pr-1 pt-1 text-center font-medium"
            />
          </div>
        )}
      </div>
      {/* Estimated Savings for Mobile */}
      <div className="tablet:hidden">
        <hr className="mt-6 text-light-gray" />
        <div className="mt-6 flex items-start justify-center self-stretch">
          <div className="flex flex-col items-center justify-center gap-4">
            {totalSavingsHeader && (
              <p className="text-base  leading-6">{totalSavingsHeader}</p>
            )}
            <h2 className="text-center text-[64px] leading-[120%]">
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(formData.estimatedSavings)}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
