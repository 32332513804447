import React, { useEffect, useState, useCallback, SetStateAction } from 'react';
import debounce from 'lodash.debounce';
import { SavingsForm } from './SavingsForm';
import { SavingsChart } from './SavingsChart';
import { IFinancialCalculatorInputModuleFields } from '@/types/contentful';
import { trackWithFingerprint } from '~/utils/trackWithFingerprint';
import RichText from '../RichText';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Block, Inline } from '@contentful/rich-text-types';
import { usePublicRuntimeConfig } from '~/hooks/usePublicRuntimeConfig';

const RICH_TEXT_OVERRIDE: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
    return <p className="text-sm text-black">{children}</p>;
  }
};

interface CompoundInterestComponents {
  principal: number;
  annualRate: number;
  t: number;
  additionalContribution: number;
  contributionPeriods: number;
}

export const SavingsCalculator = (
  fields: IFinancialCalculatorInputModuleFields
) => {
  const { moduleHeader, moduleSubheader } = fields;
  const [formManipulated, setFormManipulated] = useState(false);
  const [currentSavings, setCurrentSavings] = useState('100');
  const [rateOfReturn, setRateOfReturn] = useState<number | 'custom'>(0.05);
  const [customRate, setCustomRate] = useState<string>('0');
  const [extraContribution, setExtraContribution] = useState('10');
  const [contributionFrequency, setContributionFrequency] = useState(12);
  const [estimatedSavings, setEstimatedSavings] = useState(0);
  const [chartData, setChartData] = useState([] as any);
  const [savingsAgeRange, setSavingsAgeRange] = useState<[number, number]>([
    8, 16
  ]);

  const { webregUrl } = usePublicRuntimeConfig();

  /**
   * Debounced function for tracking form changes.
   * This ensures that tracking is only performed after 500ms of inactivity.
   */
  const debouncedTrackFormChanges = useCallback(
    debounce((data: any) => {
      trackWithFingerprint('Form Manipulated', data);
    }, 500),
    []
  );

  const formManipulate = useCallback(
    <T,>(
      field: keyof typeof formData,
      setter: React.Dispatch<React.SetStateAction<T>>,
      value: React.SetStateAction<T>
    ) => {
      setter(value);
      if (!formManipulated) {
        setFormManipulated(true);
      }
    },
    [formManipulated]
  );

  useEffect(() => {
    const latestFormData = {
      form_type: 'Savings Calculator',
      current_savings: String(currentSavings),
      rate_of_return:
        rateOfReturn === 'custom' ? String(customRate) : rateOfReturn,
      extra_contribution: String(extraContribution),
      contribution_frequency: Number(contributionFrequency),
      savings_age_range: savingsAgeRange
    };

    debouncedTrackFormChanges(latestFormData);
  }, [
    currentSavings,
    rateOfReturn,
    customRate,
    extraContribution,
    contributionFrequency,
    savingsAgeRange
  ]);

  /**
   * Define state handlers that utilize formManipulate.
   */
  const stateHandlers = {
    setCurrentSavings: (value: SetStateAction<string>) =>
      formManipulate('currentSavings', setCurrentSavings, value),
    setRateOfReturn: (value: SetStateAction<number | 'custom'>) =>
      formManipulate('rateOfReturn', setRateOfReturn, value),
    setCustomRate: (value: SetStateAction<string>) =>
      formManipulate('customRate', setCustomRate, value),
    setExtraContribution: (value: SetStateAction<string>) =>
      formManipulate('extraContribution', setExtraContribution, value),
    setContributionFrequency: (value: SetStateAction<number>) =>
      formManipulate('contributionFrequency', setContributionFrequency, value),
    setSavingsAgeRange: (value: SetStateAction<[number, number]>) =>
      formManipulate('savingsAgeRange', setSavingsAgeRange, value)
  };

  /**
   * Aggregated form data to pass to child components.
   */
  const formData = {
    currentSavings,
    rateOfReturn,
    customRate,
    extraContribution,
    contributionFrequency,
    savingsAgeRange,
    estimatedSavings
  };

  /**
   * Effect to perform initial calculation on component mount.
   */
  useEffect(() => {
    const initialComponents: CompoundInterestComponents = {
      principal: parseFloat(currentSavings),
      annualRate:
        rateOfReturn === 'custom'
          ? isNaN(parseFloat(customRate))
            ? 0
            : parseFloat(customRate) / 100
          : rateOfReturn,
      t: savingsAgeRange[1] - savingsAgeRange[0],
      additionalContribution: parseFloat(extraContribution),
      contributionPeriods: contributionFrequency
    };
    handleCalculate(initialComponents);
    generateChartData(initialComponents);
  }, []);

  /**
   * Effect to perform calculation whenever relevant state changes.
   */
  useEffect(() => {
    const components: CompoundInterestComponents = {
      principal: parseFloat(currentSavings),
      annualRate:
        rateOfReturn === 'custom'
          ? isNaN(parseFloat(customRate))
            ? 0
            : parseFloat(customRate) / 100
          : rateOfReturn,
      t: savingsAgeRange[1] - savingsAgeRange[0],
      additionalContribution: parseFloat(extraContribution),
      contributionPeriods: contributionFrequency
    };
    handleCalculate(components);
    generateChartData(components);
  }, [
    currentSavings,
    savingsAgeRange,
    rateOfReturn,
    extraContribution,
    contributionFrequency,
    customRate
  ]);

  /**
   * Function to calculate total savings and contributions.
   * @param components - The input components for calculation.
   * @returns An object containing total and totalContributions.
   */
  const calculateTotal = (components: CompoundInterestComponents) => {
    const {
      principal,
      annualRate,
      t,
      additionalContribution,
      contributionPeriods
    } = components;
    let total = principal * Math.pow(1 + annualRate, t);
    let totalContributions = principal;
    for (let i = 1; i <= t * contributionPeriods; i++) {
      total +=
        additionalContribution *
        Math.pow(1 + annualRate, t - i / contributionPeriods);
      totalContributions += additionalContribution;
    }
    return { total, totalContributions };
  };

  /**
   * Function to generate chart data based on calculation components.
   * @param components - The input components for generating chart data.
   */
  const generateChartData = (components: CompoundInterestComponents) => {
    const data = [];
    const startingAge = savingsAgeRange[0];
    for (let year = 0; year <= components.t; year++) {
      const { total, totalContributions } = calculateTotal({
        ...components,
        t: year
      });
      data.push({
        age: startingAge + year,
        saved: totalContributions.toFixed(2),
        total: total.toFixed(2),
        earned: (total - totalContributions).toFixed(2)
      });
    }
    setChartData(data);
  };

  /**
   * Function to perform calculation and update estimated savings.
   * @param components - The input components for calculation.
   */
  const handleCalculate = useCallback(
    (components: CompoundInterestComponents) => {
      const { total } = calculateTotal(components);
      setEstimatedSavings(parseFloat(total.toFixed(2)));
    },
    []
  );

  /**
   * Cleanup debounced functions on component unmount to prevent memory leaks.
   */
  useEffect(() => {
    return () => {
      debouncedTrackFormChanges.cancel();
    };
  }, [debouncedTrackFormChanges]);

  return (
    <div className="block-container">
      <div className="layout items-center !py-0 [&>div]:tablet:px-3 [&>div]:desktop:px-4">
        {(moduleHeader || moduleSubheader) && (
          <div className="mb-6 max-w-2xl tablet:mb-8">
            <h1 className="mb-4 text-center text-heading2-mobile leading-8 tablet:text-heading2 tablet:leading-12">
              {moduleHeader}
            </h1>
            <p className="text-center text-base leading-6 tablet:text-xl">
              {moduleSubheader}
            </p>
          </div>
        )}
        <div className="desktop:10/12 tablet:11/12 flex w-full flex-col gap-6 desktop:flex-row desktop:gap-8">
          <SavingsForm
            stateHandlers={stateHandlers}
            formData={formData}
            {...fields}
          />
          <SavingsChart
            chartData={chartData}
            estimatedSavings={estimatedSavings}
            savingsAgeRange={savingsAgeRange}
            {...fields}
          />
        </div>
        {fields.ctaText && (
          <button
            className={`mt-6 flex w-full min-w-32 items-center justify-center rounded-lg px-4 py-3 text-center text-base font-medium leading-4 tablet:hidden`}
            {...((fields.ctaColor || fields.ctaTextColor) && {
              style: {
                backgroundColor: fields.ctaColor,
                color: fields.ctaTextColor
              }
            })}
          >
            <a
              type="button"
              href={
                fields.registrationButton ? webregUrl : fields.ctaDestination
              }
            >
              {fields.ctaText}
            </a>
          </button>
        )}
        <div className="mr-auto mt-4">
          {fields.disclaimer && (
            <RichText
              data={fields.disclaimer}
              overrideOptions={RICH_TEXT_OVERRIDE}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SavingsCalculator;
